import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const RootDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 15px',
  '@media (min-width: 996px)': {
    marginTop: '100px',
  },
});

const TitleContainer = styled('div')({
  display: 'flex',
  width: '100%',
  textAlign: 'center',
  justifyContent: 'center',
});

const TitleTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: '22px',
  fontWeight: 400,
  lineHeight: '33px',
  letterSpacing: '0.15px',
  color: '#1C3D72',
  marginBottom: '40px',
  '@media (min-width: 768px)': {
    fontSize: '36px',
    lineHeight: '54px',
    padding: '0px 120px',
  },
});

const InformationDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  marginLeft: '12px',
  [theme.breakpoints.down(550)]: {
    marginLeft: '0px',
  },
}));

const SubTitleTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '30px',
  letterSpacing: '0.15px',
  color: '#1C3D72',
  marginBottom: '14px',
  '@media (min-width: 996px)': {
    fontSize: '30px',
    lineHeight: '45px',
    letterSpacing: '0.15px',
  },
});

const DescriptionTypography = styled(Typography)({
  display: 'inline',
  fontFamily: 'Poppins',
  fontWeight: 300,
  fontSize: '14px',
  lineHeight: '21px',
  letterSpacing: '0.15px',
  '@media (min-width: 996px)': {
    fontSize: '24px',
    lineHeight: '36px',
    letterSpacing: '0.15px',
  },
});

const InformationContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '90%',
  alignItems: 'center',
  margin: '40px 0px',
  '@media (min-width: 768px)': {
    flexDirection: 'row',
    width: '70%',
    margin: '20px 0px',
  },
  '@media (min-width: 996px)': {
    margin: '40px 0px',
  },
});

const TextContainer = styled('div')(({ theme }) => ({
  textAlign: 'left',
  marginLeft: '30px',
  width: '80%',
  [theme.breakpoints.down(550)]: {
    marginLeft: '0px',
  },
  [theme.breakpoints.down(600)]: {
    textAlign: 'center',
  },
}));

const GlassesImageContainer = styled('div')({
  position: 'relative',
  height: '101px',
  width: '193px',
  marginBottom: '20px',
  marginRight: '30px',
  '@media (min-width: 996px)': {
    height: '168px',
    width: '320px',
    marginBottom: '0',
    marginLeft: '-30px',
  },
});

const PillsImageShowTop = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up(768)]: {
    display: 'none',
  },
}));

const PillsImageShowRight = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down(768)]: {
    display: 'none',
  },
}));

const PillsImageContainer = styled('div')({
  position: 'relative',
  height: '120px',
  width: '107px',
  marginBottom: '20px',
  '@media (min-width: 996px)': {
    height: '211px',
    width: '237px',
    marginLeft: '40px',
  },
});

const IconContainer = styled('span')({
  position: 'absolute',
  height: '14px',
  width: '14px',
  marginTop: '3px',
  marginLeft: '4px',
  '@media (min-width: 996px)': {
    height: '24px',
    width: '24px',
    marginTop: '5px',
    marginLeft: '8px',
  },
});

const IconTextContainer = styled('div')({
  alignItems: 'flex-start',
});

export {
  RootDiv,
  TitleContainer,
  TitleTypography,
  SubTitleTypography,
  DescriptionTypography,
  InformationContainer,
  TextContainer,
  GlassesImageContainer,
  PillsImageContainer,
  InformationDiv,
  IconContainer,
  IconTextContainer,
  PillsImageShowTop,
  PillsImageShowRight,
};
