import React, { useState } from 'react';
import {
  RootDiv,
  GiftAmountTypography,
  ValidityTypography,
  CloseIconButton,
} from './referralBannerDiscount.styles';
import { capitalizeWord } from '@utils';
import ClearIcon from '@mui/icons-material/Clear';

interface ReferralBannerDiscountProps {
  name: string;
  lastName: string;
  amount: number;
}

export function ReferralBannerDiscount(props: ReferralBannerDiscountProps) {
  const [isVisible, setIsVisible] = useState(true);
  const { name, lastName, amount } = props;
  const message = `${capitalizeWord(name)}${
    lastName ? ` ${lastName.charAt(0).toUpperCase()}.` : ''
  } te regaló ${amount?.toLocaleString('es-CL', {
    style: 'currency',
    currency: 'CLP',
  })} de descuento!`;

  if (!isVisible) return null;

  return (
    <RootDiv>
      <CloseIconButton onClick={() => setIsVisible(false)}>
        <ClearIcon />
      </CloseIconButton>
      <GiftAmountTypography>{message}</GiftAmountTypography>
      <ValidityTypography>Válido para clientes nuevos</ValidityTypography>
    </RootDiv>
  );
}
