import {
  RootDiv,
  LogoContainer,
  TextContainer,
  TitleTypography,
  DescriptionTypography,
  DescriptionWidth,
} from './ISPBanner.styles';
import Image from 'next/image';
import { useWindowSize } from '@hooks/useWindowSize';

export function ISPBanner() {
  const { width } = useWindowSize();

  return (
    <RootDiv>
      {width > 992 && (
        <LogoContainer>
          <Image
            layout="fill"
            src="assets/images/ISPLogo2.webp"
            alt="isp"
            objectFit="contain"
          />
        </LogoContainer>
      )}

      <TextContainer>
        <TitleTypography variant="h2">
          Estamos regulados por el ISP{' '}
        </TitleTypography>
        {width < 992 && width > 768 ? (
          <LogoContainer>
            <Image
              layout="fill"
              src="assets/images/ISPLogo2.webp"
              alt="isp"
              objectFit="contain"
            />
          </LogoContainer>
        ) : (
          width < 768 && (
            <LogoContainer small>
              <Image
                layout="fill"
                src="assets/images/ISPLogo2.webp"
                alt="isp"
                objectFit="contain"
              />
            </LogoContainer>
          )
        )}
        <DescriptionWidth>
          <DescriptionTypography>
            Autorizados para despachar medicamentos con receta simple y retenida
          </DescriptionTypography>
        </DescriptionWidth>
      </TextContainer>
    </RootDiv>
  );
}
