import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const RootDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '450px',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  background:
    'linear-gradient(180deg, #FFFFFF 0%, #EFF7FF 27.6%, #EFF7FF 53.12%, #EFF7FF 76.04%, #FFFFFF 100%)',
  '@media (min-width: 996px)': {
    height: '550px',
    width: '100%',
  },
});

const TitleTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: '22px',
  fontWeight: 400,
  lineHeight: '33px',
  letterSpacing: '0.15000000596046448px',
  textAlign: 'center',
  color: '#1C3D72',
  marginBottom: '50px',
  '@media (min-width: 768px)': {
    fontSize: '26px',
    lineHeight: '39px',
    letterSpacing: '0.15000000596046448px',
  },
  '@media (min-width: 996px)': {
    fontSize: '36px',
    lineHeight: '54px',
    letterSpacing: '0.15000000596046448px',
  },
});

const ListDiv = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  width: '100%',
  display: 'grid',
  gridTemplateRows: 'auto auto',
  gridAutoFlow: 'column',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  justifyContent: 'space-between',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */,
  [theme.breakpoints.down(550)]: {
    justifyContent: 'start',
    padding: '0px 20px',
  },
  [theme.breakpoints.up(850)]: {
    padding: '0px 50px',
  },
}));

const LogoDiv = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '108px',
  width: '108px',
  margin: '0px 40px',
  [theme.breakpoints.down(750)]: {
    margin: '0px 3px !important',
  },
}));

export { RootDiv, TitleTypography, ListDiv, LogoDiv };
