import { useMemo } from 'react';
import { Slider } from '@components/Slider';
import { Comment } from '../components/Comment';
import {
  RootDiv,
  TitleContainer,
  TitleTypography,
  SliderContainerDiv,
} from './commentsSection.styles';

export function CommentsSection() {
  const COMMENTS = useMemo(
    () => [
      {
        name: 'Katerine Hrdalo',
        photo: '/assets/images/landing/katherine.hrdalo.webp',
        comment:
          'Lo mejor Meki. Lo descubrí y compré mi medicamento mucho más barato que en las demás farmacias!',
      },
      {
        name: 'María Ines Herrera',
        photo: '/assets/images/landing/maria.ines.herrera.webp',
        comment:
          'Tienen todo lo que se requiere y a unos precios extraordinariamente bajos… además cuenta con todas las facilidades para comprar por internet y entrega super rápida!!!',
      },
      {
        name: 'Vivian Liddle',
        photo: '/assets/images/landing/vivian.liddle.webp',
        comment:
          'Antes gastaba una barbaridad mensual en farmacia. Ahora gasto muchísimo menos! Excelente servicio!',
      },
      {
        name: 'Ana Maria Cavin',
        photo: '/assets/images/landing/ana.maria.cavin.webp',
        comment:
          'Muy confiables, llegan siempre a tiempo, cumplen, están en contacto conmigo que es muy bueno.. siempre tengo respuesta',
      },
      {
        name: 'Paula Bouchon',
        photo: '/assets/images/landing/paula.bouchon.webp',
        comment:
          'Me encanta Meki 🤩 Tengo todos los remedios en la fecha que los necesito y mucho más barato que en cualquier farmacia y además sin salir de mi casa',
      },
      {
        name: 'Francisca Lyon',
        photo: '/assets/images/landing/francisca.lyon.webp',
        comment:
          'Lo que más me gusta es que me recuerden cuándo debo hacer la próxima compra 🗓️, los precios y el profesionalismo',
      },
      {
        name: 'Rafael Valdés',
        photo: '/assets/images/landing/rafael.valdes.webp',
        comment:
          'Muchas gracias. Excelente servicio 👌🏻 Cualquier producto que necesite les compraré ',
      },
      {
        name: 'Sylvia Alarcón',
        photo: '/assets/images/landing/sylvia.alarcon.webp',
        comment:
          'Lo mejor es que cuentan con medicamentos muy difíciles de encontrar en otras partes 🔍',
      },
    ],
    [],
  );

  return (
    <RootDiv>
      <TitleContainer>
        <TitleTypography variant="h2">
          Buenos precios, mejor servicio
        </TitleTypography>
      </TitleContainer>

      <SliderContainerDiv>
        <Slider
          items={COMMENTS.map((comment, i) => (
            <Comment key={i} {...comment} />
          ))}
          cellHeight="auto"
          cols={8}
          commentsTileStyle={true}
        />
      </SliderContainerDiv>
    </RootDiv>
  );
}
