import Image from 'next/image';

import {
  CommentCard,
  CommentHeader,
  CommentHeaderTypography,
  CommentBodyTypography,
  CommentBodyContainer,
  ImageContainer,
  IconContainer,
} from './Comment.styles';

interface CommentProps {
  name: string;
  photo: string;
  comment: string;
}

export function Comment(props: CommentProps) {
  const { comment, name, photo } = props;
  return (
    <CommentCard key={name}>
      <CommentHeader>
        <IconContainer>
          <Image
            layout="fill"
            src={photo}
            alt="sylvia.alarcon"
            objectFit="contain"
          />
        </IconContainer>
        <CommentHeaderTypography> {name} </CommentHeaderTypography>
      </CommentHeader>

      <CommentBodyContainer>
        <CommentBodyTypography>
          {'"'}
          {comment}
          {'"'}
        </CommentBodyTypography>
      </CommentBodyContainer>

      <ImageContainer>
        <Image
          layout="fill"
          src="/assets/images/home/stars.webp"
          alt="stars"
          objectFit="contain"
        />
      </ImageContainer>
    </CommentCard>
  );
}
