/*
 * File: categories.tsx
 * Project: meki
 * File Created: Monday, 6th December 2021 2:58:50 pm
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Monday, 28th November 2022 2:52:40 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { Category } from '@interfaces';
import React from 'react';
import { HomeCategoriesSlider } from '../components/categoriesSlider';
import categories from '../categoriesData';

import { CategoriesSliderSkeleton } from '@components/Skeletons';
import {
  RootDiv,
  CategoriesSliderContainerDiv,
  BlueBackgroundSection,
} from './categories.styles';

interface HomeCategoriesProps {
  sliderItemVersion?: 'version1' | 'version2'; // version1 = used in home logged out, version2 = used in home logged in
}

export function HomeCategories(props: HomeCategoriesProps) {
  const { sliderItemVersion } = props;
  // App state
  // -- Query: Home Products
  const categoriesData: Category[] = categories as Category[];

  return (
    <>
      <RootDiv>
        <BlueBackgroundSection />
        <CategoriesSliderContainerDiv>
          {categories.length ? (
            <HomeCategoriesSlider
              categories={categoriesData}
              version={sliderItemVersion}
              location={'landing'}
            />
          ) : (
            <CategoriesSliderSkeleton />
          )}
        </CategoriesSliderContainerDiv>
      </RootDiv>
    </>
  );
}
