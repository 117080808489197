import { useState, useMemo } from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { AccordionSummary, AccordionDetails } from '@mui/material';
import { useSegment } from '@hooks/useSegment';
import { getRoute } from '@utils';
import { useRouter } from 'next/router';
import {
  RootDiv,
  TitleTypography,
  QuestionTypography,
  AnswerTypography,
  CustomAccordion,
  AnswerTypographyLink,
} from './faqSection.styles';

export function FaqSection() {
  const router = useRouter();
  const { track } = useSegment();
  const QUESTIONS = useMemo(
    () => [
      {
        question: '¿Puedo comprar con receta simple o retenida?',
        answer:
          'Sí, puedes comprar medicamentos con receta simple o retenida. Para esto deberás subir una foto de la receta y, luego de que hagas la compra, la foto será revisada por uno de nuestros químicos farmacéuticos. En caso de ser necesario, coordinaremos el retiro de la receta al momento de la entrega.',
      },
      {
        question: '¿En qué comunas tienen cobertura?',
        answer:
          'Actualmente despachamos en la Provincia de Santiago y algunos sectores de Colina, San Bernardo, Puente Alto y Lampa.',
        subAnswer: 'Conoce nuestra ',
        subAnswerLink: 'zona de despacho',
        subAnswer2:
          'Si estás fuera de la zona de despacho, puedes realizar tu pedido seleccionando la opción de retiro en tienda.',
        link: getRoute('deliveryZones'),
      },
      {
        question: '¿Qué alternativas de pago tengo?',
        answer:
          'Puedes pagar con tarjetas de débito y crédito, y también con transferencia bancaria.',
      },
      {
        question: '¿Puedo devolver un medicamento?',
        answer:
          'Sí, tienes 10 días para devolver un medicamento si te equivocaste o arrepentiste de comprarlo. No podrás devolver productos abiertos, en mal estado, productos con receta retenida o que necesiten refrigeración.',
      },
    ],
    [],
  );
  const [open, setOpen] = useState<Record<string, boolean>>({});

  const handleClick = (index: string) => {
    setOpen({ ...open, [index]: !open[index] });

    if (!open[index]) {
      // only track when question is opened (not closed)
      void track('faq answers view', {
        question: QUESTIONS[Number(index)].question,
      });
    }
  };

  return (
    <RootDiv>
      <TitleTypography variant="h2"> Preguntas frecuentes </TitleTypography>
      {QUESTIONS.map((question, index) => (
        <CustomAccordion
          key={index}
          boxOpen={open[index]}
          onClick={() => handleClick(index.toString())}
          disableGutters
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <QuestionTypography>{question.question}</QuestionTypography>
          </AccordionSummary>
          <AccordionDetails>
            <AnswerTypography> {question.answer} </AnswerTypography>
            {question.subAnswer && (
              <>
                <AnswerTypography>
                  {question.subAnswer}{' '}
                  <AnswerTypographyLink
                    onClick={() => router.push(question.link)}
                  >
                    {question.subAnswerLink}{' '}
                  </AnswerTypographyLink>
                </AnswerTypography>
                <AnswerTypography>{question.subAnswer2}</AnswerTypography>
              </>
            )}
          </AccordionDetails>
        </CustomAccordion>
      ))}
    </RootDiv>
  );
}
