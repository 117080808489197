import { styled } from '@mui/system';

interface DotProps {
  active: boolean;
}

const RootDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#FFFFFF',
  marginBottom: '130px',
  marginTop: '80px',
});

const SliderContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  overflow: 'auto',
  display: 'flex',
  scrollSnapType: 'x mandatory',
  scrollBehavior: 'smooth',
  overflowY: 'hidden',
  width: '810px',
  height: '243px',
  backgroundColor: '#E0E0DF',
  borderRadius: '10px',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  marginBottom: '10px',
  [theme.breakpoints.down(870)]: {
    marginTop: '20px',
    width: '330px',
    height: '345px',
  },
}));

const SliderItem = styled('div')(({ theme }) => ({
  scrollSnapAlign: 'start',
  flexShrink: 0,
  width: '810px',
  height: '243px',
  backgroundColor: '#E0E0DF',
  [theme.breakpoints.down(870)]: {
    width: '330px',
    height: '345px',
  },
}));

const BannerContainerWide = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '810px',
  height: '243px',
  [theme.breakpoints.down(870)]: {
    display: 'none',
  },
}));

const BannerContainerSmall = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '330px',
  height: '345px',
  [theme.breakpoints.up(870)]: {
    display: 'none',
  },
}));

const DotsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
});

const Dot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active',
})<DotProps>(({ active }) => ({
  width: '7px',
  height: '7px',
  borderRadius: '50%',
  backgroundColor: active ? '#B3B3B3' : '#EAEAEA',
}));

const LinkRedirect = styled('a')({
  textDecoration: 'none',
});

export {
  RootDiv,
  SliderContainer,
  SliderItem,
  BannerContainerWide,
  BannerContainerSmall,
  DotsContainer,
  Dot,
  LinkRedirect,
};
