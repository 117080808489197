import { useCallback, useState } from 'react';
import Image from 'next/image';
import { BioequivalenceDialog } from '../components/BioequivalenceDialog';
import { SearchBackdrop } from '@screens/search/searchBackdrop';
import { useSegment } from '@hooks/useSegment';
import {
  RootDiv,
  TitleContainer,
  TitleTypography,
  SubTitleTypography,
  DescriptionTypography,
  InformationContainer,
  TextContainer,
  GlassesImageContainer,
  PillsImageContainer,
  InformationDiv,
  IconContainer,
  IconTextContainer,
  PillsImageShowTop,
  PillsImageShowRight,
} from './transparencySection.styles';

export function TransparencySection() {
  const { track } = useSegment();

  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [toggleBackdrop, setToggleBackdrop] = useState<boolean>(false);

  const handleBackDropClick = useCallback(() => {
    setToggleBackdrop(true);
  }, []);

  const handleOpenDialog = useCallback(() => {
    setToggleBackdrop(true);
    setInfoDialogOpen(true);
    void track('bioequivalent info dialog opened');
  }, [track]);

  const handleCloseDialog = useCallback(() => {
    setInfoDialogOpen(false);
    setToggleBackdrop(false);
  }, []);

  return (
    <>
      <RootDiv>
        <TitleContainer>
          <TitleTypography variant="h2">
            Somos más transparentes para que gastes menos
          </TitleTypography>
        </TitleContainer>

        <InformationDiv>
          {infoDialogOpen && (
            <BioequivalenceDialog handleCloseDialog={handleCloseDialog} />
          )}
          <InformationContainer>
            <GlassesImageContainer>
              <Image
                layout="fill"
                src="assets/images/home/glasses-and-pills.webp"
                alt="glasses_and_pills"
                objectFit="contain"
              />
            </GlassesImageContainer>
            <TextContainer>
              <SubTitleTypography> Compara precios </SubTitleTypography>
              <DescriptionTypography>
                Te mostramos nuestros precios y el de las cadenas para que
                elijas la mejor opción
              </DescriptionTypography>
            </TextContainer>
          </InformationContainer>

          <InformationContainer>
            <PillsImageShowTop>
              <PillsImageContainer>
                <Image
                  layout="fill"
                  src="assets/images/home/pills.webp"
                  alt="glasses_and_pills"
                  objectFit="contain"
                />
              </PillsImageContainer>
            </PillsImageShowTop>
            <TextContainer>
              <SubTitleTypography>
                Más alternativas bioequivalentes
              </SubTitleTypography>
              <IconTextContainer>
                <DescriptionTypography>
                  Busca los medicamentos que necesitas y podrás ver las
                  alternativas bioequivalentes de menor valor
                  <IconContainer>
                    <Image
                      layout="fill"
                      src="assets/images/home/question.webp"
                      alt="?"
                      objectFit="contain"
                      onClick={() => {
                        handleOpenDialog();
                      }}
                    />
                  </IconContainer>
                </DescriptionTypography>
              </IconTextContainer>
            </TextContainer>
            <PillsImageShowRight>
              <PillsImageContainer>
                <Image
                  layout="fill"
                  src="assets/images/home/pills.webp"
                  alt="glasses_and_pills"
                  objectFit="contain"
                />
              </PillsImageContainer>
            </PillsImageShowRight>
          </InformationContainer>
        </InformationDiv>
      </RootDiv>
      <SearchBackdrop
        toggleBackdrop={toggleBackdrop}
        handleToggleClick={handleBackDropClick}
      />
    </>
  );
}
