import { Typography } from '@mui/material';
import { styled } from '@mui/system';

interface LogoContainerProps {
  small?: boolean;
}

const RootDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '700px',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '-10vh',
  background:
    'linear-gradient(180deg, #FFFFFF 0%, #EFF7FF 27.6%, #EFF7FF 53.12%, #EFF7FF 76.04%, #FFFFFF 100%)',
  '@media (min-width: 768px)': {
    marginTop: '-15vh',
  },
  '@media (min-width: 992px)': {
    paddingLeft: '12px',
    flexDirection: 'row',
    marginTop: '0vh',
    height: '400px',
  },
  '@media (min-width: 1119px)': {
    marginTop: '-15vh',
  },
  [theme.breakpoints.down(500)]: {
    height: '60vh',
  },
}));

const LogoContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'small',
})<LogoContainerProps>(({ small }) => ({
  position: 'relative',
  height: small ? '125px' : '172px',
  width: small ? '113px' : '190px',
  margin: '8px 0px',
}));

const TextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  paddingLeft: 0,
  '@media (min-width: 992px)': {
    alignItems: 'flex-start',
    textAlign: 'left',
    paddingLeft: '30px',
  },
});

const TitleTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: '22px',
  fontWeight: 400,
  lineHeight: '33px',
  letterSpacing: '0.15px',
  color: '#1C3D72',
  '@media (min-width: 768px)': {
    fontSize: '30px',
    fontWeight: 400,
    lineHeight: '45px',
    letterSpacing: '0.15px',
  },
  '@media (min-width: 992px)': {
    fontSize: '36px',
    fontWeight: 400,
    lineHeight: '54px',
    letterSpacing: '0.15px',
  },
});

const DescriptionWidth = styled('div')({
  width: '65%',
  '@media (min-width: 768px)': {
    width: '65%',
  },
  '@media (min-width: 992px)': {
    width: '600px',
  },
});

const DescriptionTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 300,
  lineHeight: '21px',
  letterSpacing: '0.15000000596046448px',
  color: '#414046',
  '@media (min-width: 992px)': {
    fontSize: '24px',
    fontWeight: 300,
    lineHeight: '36px',
    letterSpacing: '0.15000000596046448px',
  },
});

export {
  DescriptionWidth,
  DescriptionTypography,
  RootDiv,
  LogoContainer,
  TextContainer,
  TitleTypography,
};
