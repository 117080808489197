import { getRoute } from '@utils';
import Image from 'next/image';
import Link from 'next/link';
import { useSegment } from '@hooks/useSegment';
import {
  LogoContainer,
  LeftContainer,
  RightContainer,
  RootDiv,
  UpperTextTypography,
  LowerTextTypography,
  LinkStyle,
} from './cenabastSection.styles';

export function CenabastSection() {
  const { track } = useSegment();

  const handleCenabastClick = () => {
    void track('cenabast products from home');
  };
  return (
    <RootDiv>
      <LeftContainer>
        <UpperTextTypography>Tenemos convenio con</UpperTextTypography>
        <LogoContainer>
          <Image
            layout="fill"
            src="/assets/images/cenabast-logo.webp"
            alt="CENABAST"
            objectFit="contain"
          />
        </LogoContainer>
      </LeftContainer>

      <RightContainer>
        <LowerTextTypography>
          Revisa{' '}
          <Link
            {...getRoute('subCategory', {
              categorySlug: 'medicamentos',
              subCategorySlug: 'cenabast',
              page: '1',
            }).toLink()}
            passHref
          >
            <LinkStyle onClick={handleCenabastClick}>aquí</LinkStyle>
          </Link>{' '}
          los productos disponibles
        </LowerTextTypography>
      </RightContainer>
    </RootDiv>
  );
}
