import { styled } from '@mui/system';
import { Typography } from '@mui/material';

interface TypographyProps {
  weight?: number;
  inline?: boolean;
}

const RootDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  textAlign: 'center',
  marginTop: '30px',
  overflow: 'hidden',
  '@media (min-width: 992px)': {
    flexDirection: 'row',
    textAlign: 'left',
    alignItems: 'start',
    marginTop: '100px',
    overflow: 'visible',
  },
});

const TextContainer = styled('div')({
  marginTop: '20px',
  width: '70%',
  paddingLeft: '0px',
  '@media (min-width: 768px)': {
    marginTop: '20px',
  },
  '@media (min-width: 992px)': {
    width: '37%',
    marginLeft: '8%',
    paddingLeft: '18px',
  },
});

const TitleTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'weight' && prop !== 'inline',
})<TypographyProps>(({ weight, inline }) => ({
  fontFamily: 'Poppins',
  color: '#1C3D72',
  margin: inline ? '-3px 10px -3px 10px' : '-3px 0px -3px 0px',
  flex: 1,
  fontWeight: weight ? weight : 500,
  fontSize: '22px',
  lineHeight: '33px',
  letterSpacing: '0.87px',
  '@media (min-width: 768px)': {
    fontWeight: weight ? weight : 500,
    fontSize: '30px',
    lineHeight: '45px',
    letterSpacing: '0.87px',
  },
  '@media (min-width: 992px)': {
    fontWeight: weight ? weight : 500,
    fontSize: '36px',
    lineHeight: '54px',
    letterSpacing: '0.87px',
  },
}));

const TitleTypographyBold = styled(Typography)({
  fontFamily: 'Poppins',
  color: '#1C3D72',
  fontWeight: 600,
  fontSize: '22px',
  lineHeight: '33px',
  letterSpacing: '0.15px',
  '@media (min-width: 768)': {
    fontSize: '30px',
    lineHeight: '45px',
    letterSpacing: '0.15px',
  },
  '@media (min-width: 992px)': {
    fontSize: '36px',
    lineHeight: '54px',
    letterSpacing: '0.15px',
  },
});

const DescriptionTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontWeight: 300,
  fontSize: '14px',
  lineHeight: '21px',
  letterSpacing: '0.15',
  margin: '20px 0px 30px 0px',
  color: ' #414046',
  '@media (min-width: 992px)': {
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.15 px',
  },
});

const InlineContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

const ComunesTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '27px',
  letterSpacing: '0.44 px',
  color: '#1C3D72',
});

const ImageContainer = styled('div')({
  position: 'relative',
  width: '100%',
  maxWidth: '803px',
  margin: '-650px 0 0 0',
  zIndex: -1,
  '@media (min-width: 991px)': {
    width: '100%',
    margin: '-260px 0 0 0',
  },
});

const SmallImageContainer = styled('div')({
  position: 'relative',
  margin: '20px 0 0 0',
  height: '140vw',
  width: '170vw',
  marginRight: '35vw',
  '@media (min-width: 767px)': {
    width: '608px',
    marginRight: '0px',
    height: '65vw',
  },
});

const ArrowContainer = styled('div')({
  position: 'relative',
  height: '20px',
  width: '93px',
  marginLeft: '10px',
});

const TextArrowDiv = styled('div')({
  '@media (min-width: 992px)': {
    display: 'flex',
    flexDirection: 'row',
  },
});

const LinkStyle = styled('span')({
  color: '#0277BD',
  textDecoration: 'underline',
  cursor: 'pointer',
});

export {
  RootDiv,
  InlineContainer,
  TextContainer,
  TitleTypography,
  DescriptionTypography,
  ComunesTypography,
  TitleTypographyBold,
  ImageContainer,
  ArrowContainer,
  SmallImageContainer,
  LinkStyle,
  TextArrowDiv,
};
