import { useMemo, useRef } from 'react';
import { useIntersectionObserver } from '@hooks/useIntersection';
import Image from 'next/image';
import {
  RootDiv,
  SliderContainer,
  SliderItem,
  BannerContainerWide,
  BannerContainerSmall,
  DotsContainer,
  Dot,
  LinkRedirect,
} from './bannerSliderSection.styles';
import Link from 'next/link';

export function BannerSlideSection() {
  const refFirst = useRef<HTMLDivElement | null>(null);
  const refSecond = useRef<HTMLDivElement | null>(null);

  const firstDot = useIntersectionObserver(refFirst, { threshold: 0.501 });
  const secondDot = useIntersectionObserver(refSecond, { threshold: 0.501 });

  const firstDotActive = useMemo(() => {
    if (!firstDot) return;
    return !!firstDot.isIntersecting;
  }, [firstDot]);

  const secondDotActive = useMemo(() => {
    if (!secondDot) return;
    return !!secondDot.isIntersecting;
  }, [secondDot]);

  return (
    <RootDiv>
      <SliderContainer>
        <SliderItem ref={refFirst}>
          <BannerContainerWide>
            <Link
              href="https://www.minsal.cl/medicamentos-infografia-venta-directa/"
              passHref
            >
              <LinkRedirect target="_blank">
                <Image
                  src="/assets/images/isp/medicamentos-venta-directa-banner.webp"
                  alt="Medicamentos de venta directa"
                  layout="fill"
                  objectFit="contain"
                />
              </LinkRedirect>
            </Link>
          </BannerContainerWide>
          <BannerContainerSmall>
            <Link
              href="https://www.minsal.cl/medicamentos-infografia-venta-directa/"
              passHref
            >
              <LinkRedirect target="_blank">
                <Image
                  src="/assets/images/isp/mobile-medicamentos-venta-directa-banner.webp"
                  alt="Medicamentos de venta directa"
                  layout="fill"
                  objectFit="contain"
                />
              </LinkRedirect>
            </Link>
          </BannerContainerSmall>
        </SliderItem>
        <SliderItem ref={refSecond}>
          <BannerContainerWide>
            <Link
              href="https://www.minsal.cl/medicamentos_uso_racional/"
              passHref
            >
              <LinkRedirect target="_blank">
                <Image
                  src="/assets/images/isp/informese-banner.webp"
                  alt="Informese banner"
                  layout="fill"
                  objectFit="contain"
                />
              </LinkRedirect>
            </Link>
          </BannerContainerWide>
          <BannerContainerSmall>
            <Link
              href="https://www.minsal.cl/medicamentos_uso_racional/"
              passHref
            >
              <LinkRedirect target="_blank">
                <Image
                  src="/assets/images/isp/mobile-informese-banner.webp"
                  alt="Informese banner"
                  layout="fill"
                  objectFit="contain"
                />
              </LinkRedirect>
            </Link>
          </BannerContainerSmall>
        </SliderItem>
      </SliderContainer>
      <DotsContainer>
        <Dot active={firstDotActive} />
        <Dot active={secondDotActive} />
      </DotsContainer>
    </RootDiv>
  );
}
