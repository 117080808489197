import { styled } from '@mui/system';
import { ImageList } from '@mui/material';
import ImageListItem from '@mui/material/ImageListItem';

interface ImageListItemProps {
  commentsTileStyle: boolean;
  homeSliderTileStyle: boolean;
}
interface ButtonProps {
  pointer?: boolean;
}

const RootDiv = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  position: 'relative',
});
const SliderImageList = styled(ImageList)(({ theme }) => ({
  margin: '0 auto',
  width: '95%',
  paddingLeft: '50px',
  paddingRight: '50px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */,
  scrollBehavior: 'smooth',
  '& .MuiImageList-root': {
    height: '100%',
    padding: 4,
  },
  [theme.breakpoints.down(550)]: {
    paddingLeft: '15px',
    margin: 0,
    width: '100vw',
  },
}));
const SliderImageListItem = styled(ImageListItem, {
  shouldForwardProp: (prop) =>
    prop !== 'commentsTileStyle' && prop !== 'homeSliderTileStyle',
})<ImageListItemProps>(({ theme, commentsTileStyle, homeSliderTileStyle }) => ({
  height: '100%',
  scrollSnapAlign: 'start',
  padding: 4,
  [theme.breakpoints.down(700)]: {
    scrollSnapType: 'x mandatory !important',
  },
  ...(commentsTileStyle
    ? {
        minWidth: 280,
        maxWidth: 420,
        margin: '0px 10px',
        overflow: 'visible',
        '@media (min-width: 950px)': {
          maxWidth: 294,
        },
        '@media (min-width: 1190px)': {
          maxWidth: 352,
        },
      }
    : {}),
  ...(homeSliderTileStyle ? { minWidth: 190, padding: '0px !important' } : {}),
}));
const TapButton = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: 90,
  cursor: 'pointer',
  height: '260px',
  zIndex: 1,
  top: -5,
  background:
    'linear-gradient(90deg, rgba(240,247,254,0) 0%, rgba(240,247,254,0.72875087535014) 15%, rgba(240,247,254,1) 56%, rgba(240,247,254,1) 100%);',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    border: '2px solid',
    borderColor: '#0A7ABE',
    borderRadius: '100%',
    padding: 2,
    width: 25,
    height: 25,
  },
  [theme.breakpoints.down(700)]: {
    width: 60,
  },
  [theme.breakpoints.down(550)]: {
    width: 40,
  },
}));
const ForwardButton = styled(TapButton, {
  shouldForwardProp: (prop) => prop !== 'pointer',
})<ButtonProps>(({ pointer }) => ({
  marginTop: '5px',
  right: 0,
  cursor: pointer ? 'pointer' : 'default',
}));
const BackButton = styled(TapButton, {
  shouldForwardProp: (prop) => prop !== 'pointer',
})<ButtonProps>(({ pointer }) => ({
  marginTop: '5px',
  left: 0,
  transform: 'scale(-1, 1)',
  cursor: pointer ? 'pointer' : 'default',
}));

export {
  RootDiv,
  SliderImageList,
  SliderImageListItem,
  TapButton,
  ForwardButton,
  BackButton,
};
