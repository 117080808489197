/*
 * File: Slider.tsx
 * Project: @inventures/react-lib
 * File Created: Wednesday, 28th April 2021 7:00:44 pm
 * Author: Esperanza Horn (esperanza@inventures.cl)
 * -----
 * Last Modified: Thursday, 24th November 2022 10:08:38 am
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2020 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import React, { Fragment, useCallback, useMemo, useRef } from 'react';
import { useIntersectionObserver } from '../hooks/useIntersection';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useWindowSize } from '@hooks/useWindowSize';
import {
  RootDiv,
  SliderImageList,
  SliderImageListItem,
  ForwardButton,
  BackButton,
} from './slider.styles';

type SliderProps = {
  items: React.ReactNode[];
  cellHeight?: number | 'auto' | undefined;
  cols?: number;
  spacing?: number;
  navigationSpeed?: number;
  commentsTileStyle?: boolean;
  homeSliderTileStyle?: boolean;
};

enum NavigationDirection {
  Left = 'left',
  Right = 'right',
}

export function Slider(props: SliderProps) {
  /**
   * Returns a Material UI Grid List component.
   * Slider will take up all available parent space
   *
   * @param props - defined by SliderProps.
   * @returns React Component
   *
   */
  const {
    items,
    cellHeight,
    cols,
    spacing = 8,
    navigationSpeed = 3,
    commentsTileStyle,
    homeSliderTileStyle,
  } = props;

  const { width } = useWindowSize();

  const refFirst = useRef<HTMLLIElement | null>(null);
  const refLast = useRef<HTMLLIElement | null>(null);
  const sliderRef = useRef<HTMLUListElement | null>(null);

  const leftNavigation = useIntersectionObserver(refFirst, { threshold: 1 });
  const rightNavigation = useIntersectionObserver(refLast, { threshold: 1 });

  const leftNavigationisVisible = useMemo(() => {
    if (!leftNavigation) return;
    return !!leftNavigation.isIntersecting;
  }, [leftNavigation]);

  const rightNavigationisVisible = useMemo(() => {
    if (!rightNavigation) return;
    return !!rightNavigation.isIntersecting;
  }, [rightNavigation]);

  const handleArrowClick = useCallback(
    (direction: NavigationDirection) => {
      const scrollElement = () => {
        if (!sliderRef.current) return;
        if (direction === NavigationDirection.Right) {
          sliderRef.current.scrollLeft +=
            sliderRef.current.clientWidth / navigationSpeed;
        }
        if (direction === NavigationDirection.Left) {
          sliderRef.current.scrollLeft -=
            sliderRef.current.clientWidth / navigationSpeed;
        }
      };
      scrollElement();
    },
    [navigationSpeed],
  );

  if (!items) return null;
  return (
    <RootDiv>
      <SliderImageList
        cols={cols ?? 1.5}
        rowHeight={cellHeight}
        ref={sliderRef}
        gap={spacing}
      >
        {items.map((item, index: number) => {
          const comment = (
            <SliderImageListItem
              key={index}
              commentsTileStyle={commentsTileStyle}
              homeSliderTileStyle={homeSliderTileStyle}
              ref={
                index == 0
                  ? refFirst
                  : index == items.length - 1
                  ? refLast
                  : null
              }
            >
              {item}
            </SliderImageListItem>
          );
          if (index == 0) {
            return (
              <Fragment key="0">
                {width > 1200 && !leftNavigationisVisible && (
                  <BackButton
                    pointer
                    onClick={() => handleArrowClick(NavigationDirection.Left)}
                  >
                    <ArrowForwardIosRoundedIcon color="primary" />
                  </BackButton>
                )}
                {comment}
              </Fragment>
            );
          }
          if (index === items.length - 1) {
            return (
              <Fragment key={items.length - 1}>
                {width > 1200 && !rightNavigationisVisible && (
                  <ForwardButton
                    pointer
                    onClick={() => handleArrowClick(NavigationDirection.Right)}
                  >
                    <ArrowForwardIosRoundedIcon color="primary" />
                  </ForwardButton>
                )}
                {comment}
              </Fragment>
            );
          }
          return comment;
        })}
      </SliderImageList>
    </RootDiv>
  );
}
