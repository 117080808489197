import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const RootDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '400px',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  '@media (min-width: 996px)': {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0px 201px',
    height: '500px',
    width: '100%',
  },
});

const LeftContainer = styled('div')({
  // This container corresponds to the upper side of the screen in mobile
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
});

const RightContainer = styled('div')({
  // This container corresponds to the lower side of the screen in mobile
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  '@media (min-width: 996px)': {
    width: '43%',
  },
});

const LogoContainer = styled('div')({
  position: 'relative',
  height: '74px',
  width: '294px',
  margin: '12px 0px 28px 0px',
});

const UpperTextTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '22px',
  '@media (min-width: 996px)': {
    fontSize: '24px',
  },
});

const LowerTextTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontWeight: 300,
  fontSize: '16px',
  lineHeight: '34px',
  letterSpacing: '0.26px',
  '@media (min-width: 996px)': {
    fontSize: '24px',
  },
});

const LinkStyle = styled('span')({
  color: '#0A7ABE',
  textDecoration: 'underline',
  cursor: 'pointer',
});

export {
  RootDiv,
  LeftContainer,
  RightContainer,
  LogoContainer,
  UpperTextTypography,
  LowerTextTypography,
  LinkStyle,
};
