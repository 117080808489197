import { useMemo } from 'react';
import Image from 'next/image';
import {
  RootDiv,
  TitleTypography,
  ListDiv,
  LogoDiv,
} from './workWithSection.styles';

export function WorkWithSection() {
  const logos = useMemo(
    () => [
      'saval.svg',
      'opko.svg',
      'abbott.svg',
      'pasteur.svg',
      'grunenthal.svg',
      'novonordisk.svg',
      'andromico.svg',
      'silesia.svg',
      'arama.png',
      'sandoz.png',
      'tecnoFarma.png',
      'lilly.png',
    ],
    [],
  );

  return (
    <RootDiv>
      <TitleTypography variant="h2">Trabajamos con</TitleTypography>
      <ListDiv>
        {logos.map((logo, index) => {
          return (
            <LogoDiv key={index}>
              <Image
                layout="fill"
                key={index}
                alt={logo}
                src={`/assets/images/workWith/${logo}`}
                sizes="(min-width: 800px) 240px,
                108px"
              />
            </LogoDiv>
          );
        })}
      </ListDiv>
    </RootDiv>
  );
}
