/*
 * File: categories.styles.tsx
 * Project: meki
 * File Created: Monday, 4th July 2022 6:02:39 pm
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Monday, 28th November 2022 2:58:24 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { styled } from '@mui/system';

const RootDiv = styled('div')({
  backgroundColor: 'transparent',
});
const CategoriesSliderContainerDiv = styled('div')({
  backgroundColor: 'transparent',
  paddingTop: 22,
  paddingBottom: 22,
  '@media (min-width: 800px)': {
    paddingTop: 35,
    paddingBottom: 35,
  },
});
const BlueBackgroundSection = styled('div')(({ theme }) => ({
  position: 'absolute',
  background: '#004C8C',
  height: '170px',
  width: '100%',
  marginTop: '-1px',
  [theme.breakpoints.down(768)]: {
    height: '125px',
  },
}));

export { RootDiv, CategoriesSliderContainerDiv, BlueBackgroundSection };
