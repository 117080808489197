import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const RootDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '95vh',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  background:
    'linear-gradient(180deg, #FFFFFF 0%, #EFF7FF 27.6%, #EFF7FF 53.12%, #EFF7FF 76.04%, #FFFFFF 100%)',
});

const TitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  textAlign: 'center',
  justifyContent: 'center',
  padding: '0px 40px',
  [theme.breakpoints.down(550)]: {
    padding: '0px 90px',
  },
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: '36px',
  lineHeight: '54px',
  letterSpacing: '0.15px',
  color: '#1C3D72',
  marginBottom: '90px',
  [theme.breakpoints.down(500)]: {
    fontSize: '22px',
    lineHeight: '33px',
  },
}));

const SliderContainerDiv = styled('div')({
  maxWidth: '100vw',
  minWidth: '290px',
});

export { RootDiv, TitleContainer, TitleTypography, SliderContainerDiv };
