import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const CommentCard = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '248px',
  width: '290px',
  background: '#FFFFFF',
  boxShadow: '0px 2px 10px 0px #67676740',
  borderRadius: '10px',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '19px 0px',
});

const CommentHeader = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '50px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginLeft: '35px',
});

const IconContainer = styled('div')({
  position: 'relative',
  width: '52px',
  height: '52px',
});

const ImageContainer = styled('div')({
  position: 'relative',
  width: '124px',
  height: '24px',
});

const SliderContainerDiv = styled('div')({
  margin: 'auto',
  marginBottom: 78,
  width: 'auto',
  padding: 0,
  '@media (min-width: 1200px)': {
    padding: 0,
    maxWidth: 1260,
  },
});

const TitleContainer = styled('div')({
  display: 'flex',
  width: '100%',
  textAlign: 'center',
  justifyContent: 'center',
});

const TitleTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontWeight: 500,
  fontSize: '36px',
  lineHeight: '54px',
  letterSpacing: '0.15px',
  color: '#1C3D72',
  marginBottom: '90px',
});

const CommentsListContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '80%',
  justifyContent: 'center',
  alignItems: 'center',
  overflowX: 'scroll',
});

const CommentHeaderTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#1C3D72',
  marginLeft: '11px',
});

const CommentBodyContainer = styled(Typography)({
  width: '90%',
});

const CommentBodyTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '21px',
});

export {
  CommentCard,
  CommentHeader,
  CommentHeaderTypography,
  CommentsListContainer,
  SliderContainerDiv,
  TitleContainer,
  TitleTypography,
  CommentBodyTypography,
  CommentBodyContainer,
  ImageContainer,
  IconContainer,
};
